import React, { useRef, useState } from "react";
import styles from "./AudioPlayer.module.css";

import play from "../../assets/play.png";
import pause from "../../assets/pause.png";

const AudioPlayer = ({ src, ...props }) => {
	const audioRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);
	const [isExpanded, setIsExpanded] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);

	const handleProgressBarClick = (e) => {
		const { left, width } = document
			.getElementById(styles.progress_bar)
			.getBoundingClientRect();
		const x = e.clientX - left;
		const progress = x / width;
		audioRef.current.currentTime = Math.floor(progress * duration);
	};

	return (
		<>
			<audio
				src={src}
				ref={audioRef}
				onLoadedMetadata={(e) => {
					console.log(e.target.duration);
					setDuration(e.target.duration);
				}}
				onTimeUpdate={(e) => {
					console.log(e.target.currentTime);
					setCurrentTime(e.target.currentTime);
				}}
			/>
			<div className={styles.player_wrapper}>
				<div className={styles.play_button}>
					<img
						className={styles.play_button_icon}
						src={isPlaying ? pause : play}
						style={{
							filter: duration === 0 ? "grayscale(100%)" : "none",
						}}
						onClick={() => {
							if (duration === 0) return;
							setIsExpanded(true);
							props.setIsExpanded(true);
							if (isPlaying) {
								audioRef.current.pause();
								setIsPlaying(false);
							} else {
								audioRef.current.play();
								setIsPlaying(true);
							}
						}}
					/>
					&nbsp;&nbsp;
					<span
						className={styles.duration}
						style={{
							color: duration === 0 ? "grey" : "inherit",
						}}
					>
						{!isExpanded
							? "Play"
							: Math.floor(currentTime / 60)
									.toString()
									.padStart(2, "0") +
							  ":" +
							  Math.floor(currentTime % 60)
									.toString()
									.padStart(2, "0")}
					</span>
				</div>
				<div
					id={styles.progress_bar}
					className={styles.progress_bar}
					onClick={handleProgressBarClick}
					style={
						!isExpanded
							? {
									maxWidth: "0px",
									marginRight: "0px",
							  }
							: {}
					}
				>
					<div
						className={styles.progress}
						style={{
							backgroundColor: isPlaying ? "#AD286A" : "#A85A16",
							width: `${(currentTime / duration) * 100}%`,
							borderRadius: `${(duration / currentTime) * 10}px`,
						}}
					></div>
				</div>
			</div>
		</>
	);
};

export default AudioPlayer;
