import React, { useState } from "react";
import styles from "./PodcastDropdown.module.css";

const PodcastDropdown = ({
	podcast_youtube,
	podcast_apple,
	podcast_spotify,
}) => {
	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className={styles.dropdown_wrapper}>
			<div
				className={styles.dropdown_button}
				onClick={() => {
					setIsExpanded(!isExpanded);
				}}
			>
				Get Podcast <span className={styles.dropdown_button_icon}>&gt;</span>
			</div>
			<ul
				className={styles.dropdown_content}
				style={{
					maxHeight: isExpanded ? "150px" : "0px",
				}}
			>
				<li>
					<a href={podcast_youtube}>YouTube</a>
				</li>
				<li>
					<a href={podcast_apple}>Apple</a>
				</li>
				<li>
					<a href={podcast_spotify}>Spotify</a>
				</li>
			</ul>
		</div>
	);
};

export default PodcastDropdown;
