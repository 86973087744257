import React from "react";
import styles from "./BlogCard.module.css";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonBlogCard = ({ blog }) => {
	const width = window.matchMedia("(max-width: 768px)").matches
		? "80vw"
		: "70vw";
	return (
		<div className={styles.card}>
			<div className={styles.info}>
				<Skeleton
					baseColor="#FCE0C8"
					highlightColor="#FFF4F1"
					height={23}
					width={width}
					style={{
						marginBottom: "20px",
					}}
				/>

				<Skeleton
					baseColor="#FCE0C8"
					highlightColor="#FFF4F1"
					count={window.matchMedia("(max-width: 768px)").matches ? 2.5 : 4.5}
					width={width}
				/>
			</div>
		</div>
	);
};

export default SkeletonBlogCard;
