import axios from "axios";
import React, { useEffect, useState } from "react";

import styles from "./Home.module.css";
import BlogCard from "./BlogCard";
import shakespeareLogo from "../../assets/shakespeare_logo.png";

import linkedinLogo from "../../assets/linkedin.png";
import twitterLogo from "../../assets/twitter.png";
import instagramLogo from "../../assets/instagram.png";
import SkeletonBlogCard from "./SkeletonBlogCard";

const Home = () => {
	const [blogs, setBlogs] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const getBlogs = async () => {
		try {
			const res = await axios.get("/blogs");
			console.log(res.data);

			setBlogs(res.data);
			setIsLoading(false);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getBlogs();
	}, []);

	return (
		<div className={styles.body}>
			{window.matchMedia("(max-width: 768px)").matches && (
				<a href="https://shakespeare.vision">
					<img
						className={styles.logo}
						src={shakespeareLogo}
						alt="Shakespeare Logo"
					/>
				</a>
			)}
			<div className={styles.header}>
				<div className={styles.header_left}>
					<a href="https://shakespeare.vision">
						<img
							className={styles.logo}
							src={shakespeareLogo}
							alt="Shakespeare Logo"
						/>
						<span className={styles.gradient_text}>Shakespeare AE</span>
					</a>
					{/* <a href="https://siddharth.one">Siddharth</a>
					<a href="https://pinak.shakespeare.vision">Pinak</a>
					<a href="/">Archives</a> */}
				</div>
				{window.matchMedia("(max-width: 768px)").matches ? (
					<div className={styles.header_right}>
						<a href="https://www.linkedin.com/company/shakespearemetaverse">
							<img
								className={styles.social_logo}
								src={linkedinLogo}
								alt="LinkedIn Logo"
							/>
						</a>
						<a href="https://twitter.com/ShakespeareAE">
							<img
								className={styles.social_logo}
								src={twitterLogo}
								alt="Twitter Logo"
							/>
						</a>
						<a href="https://www.instagram.com/shakespeare.vision/">
							<img
								className={styles.social_logo}
								src={instagramLogo}
								alt="Instagram Logo"
							/>
						</a>
					</div>
				) : (
					<div className={styles.header_right}>
						<a href="https://www.linkedin.com/company/shakespearemetaverse">
							LinkedIn
						</a>
						<a href="https://twitter.com/ShakespeareAE">Twitter</a>
						<a href="https://www.instagram.com/shakespeare_ae/">Instagram</a>
					</div>
				)}
			</div>
			{window.matchMedia("(max-width: 768px)").matches && (
				<hr className={styles.divider} />
			)}
			<div className={styles.cards_section}>
				<div className={styles.podcast_row}>
					<span>Podcast</span>
					<a>Apple</a>
					<a>Spotify</a>
					<a href="https://www.youtube.com/channel/UCPFZOxHsboIwG1FTwDFkJpg">
						Youtube
					</a>
				</div>
				{isLoading
					? [...Array(3)].map((_, i) => <SkeletonBlogCard />)
					: blogs.map((blog) => <BlogCard blog={blog} key={blog.id} />)}
			</div>
		</div>
	);
};

export default Home;
