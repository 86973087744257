import React, { useState } from "react";
import styles from "./BlogCard.module.css";
import { Link } from "react-router-dom";
import AudioPlayer from "./AudioPlayer";
import PodcastDropdown from "./PodcastDropdown";

const BlogCard = ({ blog }) => {
	const formatDate = (date) => {
		const dateObject = new Date(date);
		return dateObject.toLocaleString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric",
		});
	};

	const [isExpanded, setIsExpanded] = useState(false);

	return (
		<div className={styles.card}>
			{/* <img className={styles.image} src={blog.image} alt={blog.title} /> */}
			<div className={styles.info}>
				<Link
					to={`/blog/${blog.slug}`}
					style={{ all: "unset", cursor: "pointer" }}
				>
					<h2 className={styles.title}>{blog.title}</h2>
				</Link>
				<Link
					to={`/blog/${blog.slug}`}
					style={{ all: "unset", cursor: "pointer" }}
				>
					<p className={styles.description}>
						{blog.description}&nbsp;&nbsp;&nbsp;
						<span className={styles.link}>More&nbsp;&#x203a;</span>
					</p>
				</Link>
				<div className={styles.info_row}>
					<div className={styles.audio_section}>
						{blog.podcast && (
							<AudioPlayer
								src={blog.podcast_url}
								setIsExpanded={setIsExpanded}
							/>
						)}
						{blog.podcast && <PodcastDropdown />}
					</div>
					<strong
						style={{
							display:
								isExpanded && window.matchMedia("(max-width: 768px)").matches
									? "none"
									: "block",
						}}
					>
						{formatDate(blog.created_time)}
					</strong>
					<span className={styles.author}>
						<Link
							style={{ all: "unset", cursor: "pointer" }}
							to={
								blog.author == "Siddharth Mandala"
									? "https://siddharth.one"
									: blog.author == "Pinak Oza"
									? "https://pinak.shakespeare.vision"
									: "/"
							}
						>
							Author: <strong>{(blog.author + " ").split(" ")[0]}</strong>
						</Link>
					</span>
				</div>
			</div>
		</div>
	);
};

export default BlogCard;
