import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

import styles from "./Blog.module.css";
import { Helmet } from "react-helmet";

const style = `
body {
	background-color: #FFFAF2 !important;
}

img {
	background: transparent !important;
}

.notion-frame {
	background-color: #FFFAF2 !important;
	padding: 60px 8px 0 8px !important;
	box-shadow: none !important;
	max-width: 800px !important;
	left: 50% !important;
	transform: translateX(-50%) !important;
}`;

const Blog = () => {
	const params = useParams();

	const [elements, setElements] = useState("");

	const getBlog = async () => {
		try {
			const res = await axios.post(`/blogs`, {
				slug: params.slug,
				domain: "https://plastic-treatment-cc8.notion.site/",
				hasTable: true,
			});
			console.log(res.data);

			setElements(res.data);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {
		getBlog();
	}, [params.slug]);

	return (
		<div className={styles.body}>
			<Helmet
				title={
					params.slug
						.split("-")
						.join(" ")
						.substring(0, params.slug.lastIndexOf("-")) +
					" | Shakespeare AE Journal"
				}
			/>
			{parse(elements)}
			<style>{style}</style>
		</div>
	);
};

export default Blog;
